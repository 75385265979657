import { HashRouter as Router } from "react-router-dom";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import Layout from "./layout";

export default function App() {
  return (
    <>
      <Router>
        <ToastContainer
          pauseOnFocusLoss={false}
          autoClose={500}
          position="top-right"
          newestOnTop
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
        />

        {/* <Routing /> */}
        <Layout />
      </Router>
    </>
  );
}
