import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Bgone from "../../../assets/images/background/reservation-banner.jpg";
import Bgtwo from "../../../assets/images/background/dining.jpeg";
import { number, object, string } from "yup";
import { useFormik } from "formik";
import { cateringPost } from "../../../services/AuthService";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";

function Reservation({ isHome }) {
  const navigate = useNavigate();

  const CateringSchema = object().shape({
    name: string().required("Please enter name.").trim("Please enter name."),

    number: number()
      .required("A phone number is required")
      // .matches(
      //   "/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/",
      //   "Phone number is not valid"
      // )
      .positive("A phone number can't start with a minus")
      .integer("A phone number can't include a decimal point"),

    person: number().required("Please enter person"),

    message: string()
      .required("Please enter message.")
      .trim("Please enter message."),

    date: string().required("Please enter Date.").trim("Please enter Date."),

    time: string().required("Please enter Time.").trim("Please enter Time."),
  });

  const {
    handleSubmit,
    setFieldValue,
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: CateringSchema,

    initialValues: {
      name: "",
      number: "",
      date: "",
      person: "",
      time: "",
      message: "",
    },
    onSubmit: async (values, { resetForm }) => {
      //  setisLoading(true);
      console.log(values, "<<<");
      let formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("number", values.number);
      formdata.append("date", values.date);
      formdata.append("person", values.person);
      formdata.append("time", values.time);
      formdata.append("message", values.message);

      await cateringPost(formdata)
        .then((response) => {
          //  setisLoading(false);
          resetForm();
          toast("Your Request submitted Successfully", { type: "success" });
        })
        .catch((error) => {
          toast("Something went wrong", { type: "error" });
        });
    },
    onReset: () => {},
  });
  return (
    <>
      <section className="reserve-section style-two">
        <div
          className="image-layer"
          style={{ backgroundImage: `url(${Bgone})` }}
        ></div>
        <div className="auto-container">
          <div className="outer-box">
            <div className="row clearfix">
              <div className="reserv-col col-lg-8 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="title">
                    <h2>Catering</h2>
                    <div className="request-info">
                      Booking request <Link to="#">973-882-4646</Link> or fill
                      out the order form
                    </div>
                  </div>
                  <div className="default-form reservation-form">
                    <form
                      onSubmit={(e) => {
                        e?.preventDefault();
                        handleSubmit();
                      }}
                    >
                      <div className="row clearfix">
                        <div className="form-group col-lg-6 col-md-6 col-sm-12">
                          <div className="field-inner">
                            <input
                              type="text"
                              placeholder="Your Name"
                              name="name"
                              value={values.name}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              style={{
                                borderColor:
                                  touched?.name && errors?.name && "red",
                              }}
                            />
                          </div>
                          {touched?.name && errors?.name && (
                            <p className="error-message">{errors.name}</p>
                          )}
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12">
                          <div className="field-inner">
                            <input
                              type="number"
                              name="number"
                              value={values.number}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              style={{
                                borderColor:
                                  touched?.number && errors?.number && "red",
                              }}
                              placeholder="Phone Number"
                            />
                          </div>
                          {touched?.number && errors?.number && (
                            <p className="error-message">{errors.number}</p>
                          )}
                        </div>
                        <div className="form-group col-lg-4 col-md-6 col-sm-12">
                          <div className="field-inner">
                            <span className="alt-icon far fa-user"></span>
                            <input
                              type="number"
                              name="person"
                              placeholder="Persons"
                              min={0}
                              value={values.person}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              style={{
                                borderColor:
                                  touched?.person && errors?.person && "red",
                                paddingLeft: "48px",
                              }}
                            />
                            {/* <select className="l-icon">
                              <option>1 Person</option>
                              <option>2 Person</option>
                              <option>3 Person</option>
                              <option>4 Person</option>
                              <option>5 Person</option>
                              <option>6 Person</option>
                              <option>7 Person</option>
                            </select> */}
                            {/* <span className="arrow-icon far fa-angle-down"></span> */}
                          </div>
                          {touched?.person && errors?.person && (
                            <p className="error-message">{errors.person}</p>
                          )}
                        </div>
                        <div className="form-group col-lg-4 col-md-6 col-sm-12">
                          <div className="field-inner">
                            {/* <span className="alt-icon far fa-calendar"></span> */}
                            {/* <input
                              className="l-icon datepicker"
                              type="text"
                              name="fieldname"
                              placeholder="DD-MM-YYYY"
                            /> */}
                            <DatePicker
                              selected={new Date()}
                              placeholderText="DD-MM-YYYY"
                              name="date"
                              placeholder="Date*"
                              value={values.date}
                              onBlur={handleBlur}
                              onChange={(date) =>
                                setFieldValue(
                                  "date",
                                  date?.toLocaleDateString()
                                )
                              }
                            />

                            <span className="arrow-icon far fa-angle-down"></span>
                          </div>
                          {touched?.date && errors?.date && (
                            <p className="error-message">{errors.date}</p>
                          )}
                        </div>
                        <div className="form-group col-lg-4 col-md-12 col-sm-12">
                          <div className="field-inner">
                            <span className="alt-icon far fa-clock"></span>
                            <select
                              className="l-icon"
                              name="time"
                              value={values.time}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              style={{
                                borderColor:
                                  touched?.time && errors?.time && "red",
                              }}
                            >
                              <option value="">Select your time</option>

                              <option>08 : 00 am</option>
                              <option>09 : 00 am</option>
                              <option>10 : 00 am</option>
                              <option>11 : 00 am</option>
                              <option>12 : 00 pm</option>
                              <option>01 : 00 pm</option>
                              <option>02 : 00 pm</option>
                              <option>03 : 00 pm</option>
                              <option>04 : 00 pm</option>
                              <option>05 : 00 pm</option>
                              <option>06 : 00 pm</option>
                              <option>07 : 00 pm</option>
                              <option>08 : 00 pm</option>
                              <option>09 : 00 pm</option>
                              <option>10 : 00 pm</option>
                            </select>
                            <span className="arrow-icon far fa-angle-down"></span>
                          </div>
                          {touched?.time && errors?.time && (
                            <p className="error-message">{errors.time}</p>
                          )}
                        </div>
                        <div className="form-group col-lg-12 col-md-12 col-sm-12">
                          <div className="field-inner">
                            <textarea
                              placeholder="Message"
                              name="message"
                              value={values.message}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              style={{
                                borderColor:
                                  touched?.message && errors?.message && "red",
                              }}
                            ></textarea>
                          </div>
                          {touched?.message && errors?.message && (
                            <p className="error-message">{errors.message}</p>
                          )}
                        </div>
                        <div className="form-group col-lg-12 col-md-12 col-sm-12">
                          <div className="field-inner">
                            <button
                              type="submit"
                              // onClick={(e)=>e?.preventDefault()}
                              className="theme-btn btn-style-one clearfix"
                            >
                              <span className="btn-wrap">
                                <span className="text-one">Book a Slot</span>
                                <span className="text-two">Book a Slot</span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="info-col col-lg-4 col-md-12 col-sm-12">
                <div className="inner">
                  <div
                    className="img-layer"
                    style={{ backgroundImage: !isHome && `url(${Bgtwo})` }}
                  ></div>

                  <div className="data">
                    <div className="booking-info">
                      <div className="bk-title">Booking request</div>
                      <div className="bk-no">
                        <Link to="tel:973-882-4646">973-882-4646</Link>
                      </div>
                    </div>
                    <div className="separator">
                      <span></span>
                    </div>
                    <ul className="info">
                      <li>
                        <strong>Location</strong>
                        <br />
                        111 Route 46 East, Parsippany, NJ 07054
                      </li>
                      <li>
                        <strong>Lunch Time</strong>
                        <br />
                        Tuesday to Sunday <br />
                        11.30 am - 2.30pm
                      </li>
                      <li>
                        <strong>Dinner Time</strong>
                        <br />
                        Tuesday to Sunday <br />
                        05.00 pm - 10.00pm
                        <br />
                        (Monday Closed)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Reservation;
